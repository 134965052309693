import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

export const NotFoundImage = styled.img`
  max-width: 100%;
  height: auto;
`;
