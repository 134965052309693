import GlobalStyle from "./Style/GlobalStyles";
import Homepage from './Pages/Home';
import "./assets/css/styles.css"

function App() {
  return (
    <>
      <GlobalStyle />
      <Homepage />
    </>
  );
}

export default App;


